import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  useOfferletterApprovalMutation,
} from '../../../../../../features/hiringstages/offerApiSlice';
import SignatureCanvas from 'react-signature-canvas';
import { UserCircleIcon } from '@heroicons/react/24/outline';

const OfferLetter = ({ canddetails }) => {
  const [selectedPhoto, setSelectedPhoto] = useState([]);
  const [image, setImage] = useState('');
  const canddetailsObj = canddetails?.data;
  console.log(canddetailsObj)

  const [offerletterApproval] = useOfferletterApprovalMutation();
  // Signature
  const signatureRef = useRef();
  const [savedSignature, setSavedSignature] = useState(null);

  const [finalOfferLetter, setFinalOfferLetter] = useState('');
  const [offerLetterTemplate, setOfferLetterTemplate] = useState('');

  // Function to replace placeholders with actual values, including the signature
  const replacePlaceholders = (template, canddetailsObj, signatureURL) => {
    return template
      .replace(
        '[Signature]',
        signatureURL
          ? `<img src="${signatureURL}" alt="Signature" width="250" height="100"/>`
          : '[Signature]' // Keep the placeholder if no signature is available
      );
  };


  // useEffect to replace placeholders when the form loads
  useEffect(() => {
    setOfferLetterTemplate(canddetailsObj.offer_letter)

    if (offerLetterTemplate && canddetailsObj) {
      const updatedOfferLetter = replacePlaceholders(
        offerLetterTemplate,
        canddetailsObj,
        savedSignature || image // Signature image source
      );

      // Set the final offer letter with replaced placeholders
      setFinalOfferLetter(updatedOfferLetter);

      // Optionally update formik values if needed
      formik.setFieldValue('offer_letter', updatedOfferLetter);
    }
  }, [offerLetterTemplate, canddetailsObj, savedSignature, image]);

  console.log(finalOfferLetter)

  const initialValues = {
    profile_id: canddetailsObj?.id || '',
    offer_approval_status: canddetailsObj?.offer_approval_status || 0,
    offer_comments: canddetailsObj?.offer_comments || '',
    offer_letter: finalOfferLetter||canddetailsObj?.offer_letter || ''
  };
  console.log(initialValues)

  const formik = useFormik({
    initialValues,
  });
  const handleClick = async (btnStatus) => {
    let newData;
    if (btnStatus === 'Query') {
      newData = {
        ...initialValues,
        offer_approval_status: 3,
        offer_comments: 'Query',
      };
    }
    if (btnStatus === 'Reject') {
      newData = {
        ...initialValues,
        offer_approval_status: 2,
        offer_comments: 'Rejected',
      };
    }
    if (btnStatus === 'Approval') {
      newData = {
        ...initialValues,
        offer_approval_status: 1,
        offer_comments: 'Approved',
      };
    }
    try {
      const rrrr = await offerletterApproval({
        ...newData,
      }).unwrap();
      alert(`Data ${btnStatus} successfully`);
    } catch (error) {
      alert('Error');
    }
  };

  const offerStatus = (statusVal) => {
    if (statusVal === 1) {
      return (
        <div className="bg-green-700 px-6 py-2 text-white block w-full text-center">
          Offer Accepted{' '}
        </div>
      );
    } else if (statusVal === 2) {
      return (
        <div className="bg-red-600 px-3 py-2 text-white block w-full text-center">
          Offer Rejected{' '}
        </div>
      );
    } else if (statusVal === 3) {
      return (
        <div className="bg-blue-600 px-3 py-2 text-white block w-full text-center">
          Offer Query{' '}
        </div>
      );
    }
  };



  const handleClear = () => {
    signatureRef.current.clear();
  };

  const handleSave = () => {
    const dataUrl = signatureRef.current.toDataURL();
    setSavedSignature(dataUrl);
  };

  const InputChange1 = (e) => {
    // --For Multiple File Input

    const file = e.target.files[0];
    const imgname = e.target.files[0].name;
    setSelectedPhoto(e.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxSize = Math.max(img.width, img.height);
        canvas.width = maxSize;
        canvas.height = maxSize;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          img,
          (maxSize - img.width) / 2,
          (maxSize - img.height) / 2
        );
        canvas.toBlob(
          (blob) => {
            const file = new File([blob], imgname, {
              type: 'image/png',
              lastModified: Date.now(),
            });

            setImage(file);
          },
          'image/jpeg',
          0.8
        );
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className=" bg-white pb-4 rounded-lg">
          {offerStatus(canddetailsObj?.offer_approval_status)}
          <div className=" rounded-md shadow-3xl mx-0 ">
            {' '}
            <div className="flex flex-row mt-0 ">
              <div className="gap-4 rounded-lg">
                {' '}
                <div className=" bg-gray-200 pb-4 rounded-lg">

                  {/* <div className="bg-white rounded-md py-4 px-4 mx-4 my-4">
                    <span className="flex">
                      Dear
                      <p className="text-sidebar pl-1">[name]</p>
                    </span>
                    <p className="py-2">
                      Wipro is delighted to offer you the full-time position of
                      <e className="text-sidebar pl-1">[Designation]</e>
                      with an anticipated start date of
                      <e className="text-sidebar pl-1">[Joining date],</e>
                      contingent upon [background check, drug screening, etc.].{' '}
                    </p>

                    <p>
                      As the
                      <e className="text-sidebar pl-1">[Designation]</e>, you
                      will be responsible for [brief mention of job
                      responsibilities and expectations].{' '}
                    </p>

                    <p className="py-2">
                      You will report directly to [manager/supervisor name and
                      title] at [workplace location]. Working hours are from
                      [hours of day, days of week].
                    </p>

                    <p>
                      The starting salary for this position is{' '}
                      <e className="text-sidebar pl-1">[Salary]</e>
                      per month. Payment is on a monthly basis by [direct
                      deposit, check, etc.]. In addition, you will be eli gible
                      to receive [discuss additional compensation potential].
                    </p>

                    <p className="py-2">
                      {' '}
                      Wipro offers a comprehensive benefits program, which
                      includes [medical insurance, 401(k), paid time off, etc.].{' '}
                    </p>

                    <p>
                      Your employment with Wipro will be on an at-will basis,
                      which means you and the company are free to terminate
                      employment at any time, with or without cause or advance
                      notice. This letter is not a contract indicating
                      employment terms or duration.
                    </p>

                    <p className="py-2">
                      Please confirm your acceptance of this offer by signing
                      and returning this letter by [offer expiration date].
                    </p>

                    <p className="text-md font-semibold">Sincerely,</p>
                    {savedSignature && (
                      <img
                        src={savedSignature}
                        alt="Saved Signature"
                        width={250}
                        height={100}
                      />
                    )}
                    {image ? (
                      <img
                        src={
                          typeof image == 'object'
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt="upload image"
                        className="w-40 h-20"
                      />
                    ) : (
                      ''
                    )}
                    <p className="text-sidebar mt-4">[Signature]</p>
                  </div> */}
                  <div className="bg-white rounded-md  mx-4 my-4">
                    {canddetailsObj && (
                      <div
                        dangerouslySetInnerHTML={{ __html: finalOfferLetter }}
                      />
                    )}
                  </div>
                  <div className="flex">
                    <div className="mr-6  ml-4 mb-3">
                      <h1 className="pb-3">Provide Your signature</h1>
                      <SignatureCanvas
                        ref={signatureRef}
                        canvasProps={{
                          width: 250,
                          height: 100,
                          className: 'signature-canvas bg-white',
                        }}
                      />
                      <div className="signature-canvas1 mt-3">
                        <button
                          onClick={handleClear}
                          // className="mr-3
                          className="rounded-3xl border border-gray-300 bg-white py-3 px-8 mr-3 text-sm font-medium text-gray-700 shadow-sm  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Clear
                        </button>
                        <button
                          onClick={handleSave}
                          className="inline-flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-8  mr-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    {/* <div className=" "> or </div> */}
                    <div className="flex items-center">
                      <div className="kb-file-upload mt-3">
                        <div className="file-upload-box ">
                          <input
                            type="file"
                            id="fileupload1"
                            className="file-upload-input"
                            onChange={InputChange1}
                          />
                          <span className="flex">
                            {/* <UserCircleIcon
                              className="h-40 w-40 text-gray-200 "
                              aria-hidden="true"
                            /> */}
                            <button className="inline-flex  justify-center rounded-md border border-transparent bg-indigo-600 px-4 my-4  mr-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm">
                              Upload Signature
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-0 mt-0 w-full">
                    <div className=" mt-4 space-x-8 w-full flex justify-start ml-4">
                      <button
                        type="button"
                        className=" rounded-lg  bg-blue-500 hover:bg-blue-700 text-white py-2 px-7 text-sm font-medium  shadow-sm focus:outline-none "
                        onClick={() => handleClick('Query')}
                      >
                        Query
                      </button>
                      <button
                        type="button"
                        className=" rounded-lg  bg-red-600 hover:bg-red-700 text-white py-2 px-7 text-sm font-medium  shadow-sm focus:outline-none "
                        onClick={() => handleClick('Reject')}
                      >
                        Reject
                      </button>

                      <button
                        type="submit"
                        className=" rounded-lg  bg-green-400 hover:bg-green-600 text-white py-2 px-6 text-sm font-medium  shadow-sm focus:outline-none "
                        onClick={() => handleClick('Approval')}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OfferLetter;
